import React, {useState, useEffect} from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  mergePosts,
  buildCanonical,
  toPlainText
} from '../lib/helpers'
import {loadNextPageByAuthor} from '../lib/api'
import InfiniteScroll from 'react-infinite-scroller'
import Img from 'gatsby-image'
import BlogPostPreview from '../components/post/preview'
import GraphQLErrorList from '../components/graphql/error'
import SEO from '../components/seo'
import Layout from '../components/layout'
import PortableText from '../components/common/portableText'

export const query = graphql`
  query($id: String!) {
    author: sanityAuthor(_id: {eq: $id}) {
      _id
      name
      _rawBio
      slug {
        current
      }
      image {
        alt
        asset {
          fluid(maxWidth: 160, maxHeight: 160) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { authors: { elemMatch: { author: { _id: {eq: $id} } } } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            alt
            asset {
              _id
              url
              fluid(maxWidth: 360, maxHeight: 202) {
                ...GatsbySanityImageFluid
              }
            }
          }
          title
          _rawExcerpt
          slug {
            current
          }
          authors {
            _key
            author {
              image {
                alt
                asset {
                  fluid(maxWidth: 48, maxHeight: 48) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              name
              slug {
                current
              }
            }
          }
          _rawCategories(resolveReferences: {maxDepth: 1000})
        }
      }
    }
  }
`

const AuthorTemplate = props => {
  const {data, errors} = props
  const postsPerPage = 6
  const [infinite, setInfinite] = useState({loading: false, page: 1, loadMore: false, hasMore: true})

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const author = (data || {}).author
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  const [posts, setPosts] = useState(postNodes)

  const loadNewPosts = () => {
    if (!infinite.loading && infinite.hasMore) {
      setInfinite({...infinite, loading: true, loadMore: true})
    }
  }

  useEffect(() => {
    if (infinite.loadMore && infinite.hasMore) {
      const startIndex = infinite.page * postsPerPage
      const endIndex = (startIndex + postsPerPage) - 1

      loadNextPageByAuthor(startIndex, endIndex, author._id)
        .then(data => {
          const all = mergePosts(posts, data)
          setPosts(all)
          setInfinite({page: infinite.page + 1, loading: false, loadMore: false, hasMore: data.length > 0})
        })
        .catch((error) => {
          console.log(error)
          setInfinite({page: infinite.page, loading: false, loadMore: false, hasMore: false})
        })
    }
  })

  return (
    <Layout>
      <SEO
        canonical={buildCanonical(`/author/${author.slug.current}`)}
        title={author.name || 'Untitled'}
        description={toPlainText(author._rawBio)} />

      <section>
        <div className='relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8'>
          <div className='relative max-w-7xl mx-auto'>
            <div className='text-center'>
              <div className='flex justify-center align-center mb-4'>
                <Img className='object-cover object-center w-40 h-40 rounded-full mx-auto'
                  fluid={author.image.asset.fluid} alt={author.image.alt} />
              </div>
              <h1 className='text-3xl leading-9 tracking-tight font-raleway font-bold text-gray-900 sm:text-4xl sm:leading-10'>
                {author.name}
              </h1>
              {author._rawBio && (
                <div className='mt-3 max-w-4xl mx-auto text-xl leading-7 text-blue-900 opacity-60 sm:mt-4'>
                  <PortableText blocks={author._rawBio} />
                </div>
              )}
            </div>

            <InfiniteScroll
              className='mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none'
              pageStart={0}
              loadMore={() => loadNewPosts()}
              hasMore={!infinite.loading && infinite.hasMore}>
              {posts.map(post => <BlogPostPreview key={post.id} post={post} />)}
            </InfiniteScroll>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AuthorTemplate
